import { render, staticRenderFns } from "./categFrom.vue?vue&type=template&id=cbe77a60&"
import script from "./categFrom.vue?vue&type=script&lang=js&"
export * from "./categFrom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bahaaeddine/public_html/hmd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cbe77a60')) {
      api.createRecord('cbe77a60', component.options)
    } else {
      api.reload('cbe77a60', component.options)
    }
    module.hot.accept("./categFrom.vue?vue&type=template&id=cbe77a60&", function () {
      api.rerender('cbe77a60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/base/settings/product_categories/components/categFrom.vue"
export default component.exports